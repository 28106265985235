










































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class MyStudent extends Vue {
  // @Prop() private readonly data!: any
  @Prop() private readonly value!: any
  @Prop() private readonly imgUrl!: string
  @Prop() private readonly showImg!: any
  @Prop() private readonly className!: any
  @Prop() private readonly tagShow!: any
  @Prop() private readonly canClick!: any
  @Prop() private readonly contentStyle!: any

  private imgError: boolean = false
  private document = document

  private defaultImg(): any {
    this.imgError = true
    return false
  }

  private get realCanClick(): boolean {
    return this.$listeners.click !== undefined && this.canClick !== false
  }

  private onClick(): any {
    if (this.realCanClick) {
      this.$emit('click')
    }
  }
}
