














import gradeBook from '@/views/StudentDetail/StudentDetail/GradeBook.vue'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    gradeBook,
  },
})
export default class GradeBook extends Vue {}
